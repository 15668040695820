export default {
  ru: {
    recipientsPage: {
      title: 'Получатели',
      description:
        'Добавьте получателя, чтобы оформить посылку на таможне, бесплатный самовывоз или платную доставку по вашему адресу',
      addButton: 'Добавить получателя',
      edit: 'Редактировать',
      delete: 'Удалить',
    },
    recipientCreatePage: {
      title: 'Добавить получателя',
    },
    recipientUpdatePage: {
      title: 'Редактировать получателя',
    },
    recipientList: {
      title: 'Добавленные получатели',
    },
    recipientForm: {
      phone: 'Телефон',
      tin: 'Введите ИНН',
      passportSection: {
        title: 'Паспортные данные',
        description: 'Данные запрашивает таможня для растаможки груза',
        passportFullname: 'ФИО',
        passportNumber: 'Номер удостоверения',
        passportIssuedBy: 'Кем выдан',
        passportIssuedAt: 'Дата выдачи',
        passportExpiresAt: 'Срок действия',
      },
      city: 'Город',
      submit: 'Сохранить',
      submitting: 'Отправка...',
    },
    deleteRecipientModal: {
      confirmText: 'Вы уверены что хотите удалить карточку получателя?',
      cancel: 'Отмена',
      delete: 'Удалить',
    },
  },
}
