// @flow
import strings from './localization'

export const COUNTRY_NAMES = {
  kz: {
    default: 'Казахстан',
    genitive: 'Казахстане',
  },
  kg: {
    default: 'Бишкек',
    genitive: 'Бишкеке',
  },
}

export const GOOGLE_PLACES_LANGUAGE = 'ru'
export const GOOGLE_PLACES_REGION = 'KZ'

export const PARCEL_DELIVERY_ANNOTATION = {
  kz: 'Самовывоз доступен в Алматы по адресу: <a href="https://go.2gis.com/vemeh" target="_blank">Розыбакиева 238а</a>. C 10:00 до 19:00',
  kg: 'Самовывоз доступен в Бишкеке по адресу: <a href="https://go.2gis.com/o8hnt" target="_blank">Московская, 139</a>. C 10:00 до 19:00',
}

export const INSTAGRAM_ACCOUNT = {
  kz: 'shipper.kz',
  kg: 'shipperkg',
}
export const GOOGLE_ANALYTICS_ID = {
  kz: 'UA-126462280-1',
  kg: 'UA-126462280-2',
}
export const YANDEX_METRIKA_ID = {
  kz: '50488192',
  kg: '67820392',
}
export const CONTACTS = {
  kz: {
    tel: {
      raw: '+77472053355',
      formatted: '+7 (747) 205-33-55',
    },
    email: 'fast@shipper.kz',
  },
  kg: {
    tel: {
      raw: '+996707335522',
      formatted: '+996 (707) 33-55-22',
    },
    email: 'fast@shipper.kg',
  },
}

export const SUPPORT_URL = 'https://t.me/fastshipper_bot'

export const PROTECTED_ROUTES = [
  '/add-parcel',
  '/calculator',
  '/address',
  '/help',
  '/parcels',
  '/shops',
  '/user-settings',
]
export const SECTION_KEYS = {
  SIMPLE_STEPS: 'simple-steps',
  ABOUT_DELIVERY: 'about-delivery',
  CALCUCATE: 'calculate',
  EXAMPLES: 'examples',
  FAQ: 'faq',
}
export const SECTIONS = [
  {
    key: SECTION_KEYS.SIMPLE_STEPS,
    name: 'Как заказать',
  },
  {
    key: SECTION_KEYS.ABOUT_DELIVERY,
    name: 'О доставке',
  },
  {
    key: SECTION_KEYS.CALCUCATE,
    name: 'Стоимость',
  },
  {
    key: SECTION_KEYS.EXAMPLES,
    name: 'Примеры',
  },
  {
    key: SECTION_KEYS.FAQ,
    name: 'F.A.Q',
  },
]

export type ParcelStatusNavItem = {
  statusSlug: ?string,
  label: string,
  href: string,
}

export const PARCEL_STATUS_NAV_ITEMS: Array<ParcelStatusNavItem> = [
  {
    statusSlug: null,
    label: strings.dashboardNav.parcelItems.all,
    href: '/parcels',
  },
  {
    statusSlug: 'new',
    label: strings.dashboardNav.parcelItems.new,
    href: '/parcels/status/new',
  },
  {
    statusSlug: 'prepayment-invoice',
    label: strings.dashboardNav.parcelItems.prepaymentInvoice,
    href: '/parcels/status/prepayment-invoice',
  },
  {
    statusSlug: 'packaging',
    label: strings.dashboardNav.parcelItems.packaging,
    href: '/parcels/status/packaging',
  },
  {
    statusSlug: 'executing',
    label: strings.dashboardNav.parcelItems.executing,
    href: '/parcels/status/executing',
  },
  {
    statusSlug: 'final-invoice',
    label: strings.dashboardNav.parcelItems.finalInvoice,
    href: '/parcels/status/final-invoice',
  },
  {
    statusSlug: 'on-delivery',
    label: strings.dashboardNav.parcelItems.onDelivery,
    href: '/parcels/status/on-delivery',
  },
  {
    statusSlug: 'won',
    label: strings.dashboardNav.parcelItems.won,
    href: '/parcels/status/won',
  },
]

export const MAIN_NAV_ITEMS = [
  {
    label: strings.dashboardNav.userItems.calculator,
    href: '/calculator',
  },
  {
    label: strings.dashboardNav.userItems.address,
    href: '/address',
  },
  {
    label: strings.dashboardNav.userItems.recipients,
    href: '/recipients',
  },
  {
    label: strings.dashboardNav.userItems.help,
    href: '/help',
  },
  {
    label: strings.dashboardNav.userItems.settings,
    href: '/user-settings',
  },
]

export const INSURANCE_BASE_PERCENTAGE = 3
export const INSURANCE_MIN_AMOUNT = 300
