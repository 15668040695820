// @flow
import axios from 'axios'
import mapValues from 'lodash/mapValues'
import pick from 'lodash/pick'
import Router from 'next/router'
import Cookies from 'js-cookie'
import getConfig from 'next/config'

import type { AxiosPromise, Axios } from 'axios'

import * as store from './store'
import strings from '../localization'

const {
  publicRuntimeConfig: { upStage },
} = getConfig()

const prodStage = ['production', 'prod'].includes(upStage)

const API_URL = prodStage
  ? 'https://api.shipper.space/v1/'
  : 'https://api-staging.shipper.space/v1/'
export const DEFAULT_TIMEOUT = 21000
const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Key-Inflection': 'camel',
}
const axiosClient: Axios = axios.create({
  baseURL: API_URL,
  timeout: DEFAULT_TIMEOUT,
  headers: defaultHeaders,
})

const publicToken = '4ddb9e3a5eb2d780c8ff60fc563113538dbfc600'

const authRedirectInterceptor = axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      typeof error?.response?.config.url === 'string' &&
      !error?.response?.config.url.endsWith('users/sessions') &&
      error?.response?.status === 401
    ) {
      store.set('authRedirectedFrom', Router.asPath)
      store.clear('authToken')
      Cookies.remove('authToken')
      axiosClient.interceptors.response.eject(authRedirectInterceptor)

      Router.push({ pathname: '/' })
    } else {
      return Promise.reject(error)
    }
  }
)

export function getAuthenticatedClient(authToken: ?string): Axios {
  axiosClient.defaults.headers.common['Authorization'] = authToken

  return axiosClient
}

export function localFetcher(url: string) {
  const client = axios.create({
    timeout: DEFAULT_TIMEOUT,
  })
  return client.get(url).then((res) => res.data)
}

export function trackingFetcher(url: string) {
  const client = axios.create({
    baseURL: 'https://services.shipper.space/api/v1',
    timeout: DEFAULT_TIMEOUT,
    headers: {
      Authorization: `Token ${publicToken}`,
    },
  })
  return client.get(url).then((res) => res.data)
}

export function exlineFetcher(url: string) {
  const client = axios.create({
    baseURL: 'https://api.exline.systems',
    timeout: DEFAULT_TIMEOUT,
  })
  return client.get(url).then((res) => res.data)
}

export async function getFullnameByTIN(tin: string) {
  const {
    data: { fullname },
  } = await axios.get(`/fullname-by-tin/${tin}`)
  return fullname
}

export function createSquarePayment(
  sourceId: string,
  amount: number,
  referenceId: string
) {
  return axios.post('/payments', { sourceId, amount, referenceId })
}

export type UserType = {
  id: number,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  systemNumber: string,
  warehouseId: string,
  balance: number,
  hasOrders: boolean,
  regionId: number,
  address: ?string,
  tin: ?string,
  passportFullname: ?string,
}
export type WarehouseType = {
  id: string,
  isNew: boolean,
  enabled: boolean,
  locationName: string,
  annotationKey: string,
  address: {
    firstLine: string,
    secondLine: ?string,
    city: string,
    state: string,
    zip: number,
    phone: string,
    country: string,
  },
}
export type OrderType = {
  id: number,
  createdAt: number,
  description: string,
  uid: string,
  bitrixId: ?number,
  status: number,
  trackingNumber: string,
  localCarrier: ?string,
  localTrackingNumber: ?string,
  postalService: ?number,
  weight: ?number,
  dimensions: ?string,

  insured: boolean,
  insuranceAmount: ?number,

  paid: boolean,
  parcelPrice: ?number,
  price: ?number,

  deliveryType: 0 | 1,
  address: ?string,
  tin: ?string,
  passportFullname: ?string,

  parcelAttachmentUrl: ?string,
  labelAttachmentUrl: ?string,
}
export type OrderValuesType = {
  parcelPrice: number,
  trackingNumber: string,
  description: string,
}
export type UserResponse = UserType & {
  jwtToken: string,
}
export type CreateUserRequest = UserType & {
  password: string,
  passwordConfirmation: string,
}
export type CreateUserSessionRequest = {
  email: string,
  password: string,
}
export type UserPasswordResetRequest = {
  email: string,
}
export type GetOrdersResponse = Array<OrderType>
// External methods
export function createUser(
  user: CreateUserRequest
): AxiosPromise<UserResponse> {
  return axiosClient.post('users', { user })
}

export function updateUser(
  token: ?string,
  userId: number,
  user: Object
): AxiosPromise<UserResponse> {
  return axiosClient.put(
    `users/${userId}`,
    { user },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function getUserData(
  token: ?string,
  userId: number
): AxiosPromise<UserType> {
  return axiosClient.get(`users/${userId}`, {
    headers: {
      Authorization: token,
    },
  })
}

export function getCities(token: ?string) {
  return axiosClient.get('cities', {
    headers: {
      Authorization: token,
    },
  })
}

export function getExchangeRates() {
  return axiosClient.get('exchange_rates')
}

export function createUserSession(
  user: CreateUserSessionRequest
): AxiosPromise<UserResponse> {
  return axiosClient.post('users/sessions', { user })
}

export function createPasswordReset(
  user: UserPasswordResetRequest
): AxiosPromise<Object> {
  return axiosClient.post('users/password_resets', { user })
}

export function getOrdersInProgress(
  token: ?string
): AxiosPromise<GetOrdersResponse> {
  return axiosClient.get('orders/in_progress', {
    headers: {
      Authorization: token,
    },
  })
}

export function getOrdersByStatus(
  token: ?string,
  status: number
): AxiosPromise<GetOrdersResponse> {
  return axiosClient.get('orders', {
    params: { status },
    headers: {
      Authorization: token,
    },
  })
}

export function getOrder(token: ?string, orderId: number) {
  return axiosClient.get(`orders/${orderId}`, {
    headers: {
      Authorization: token,
    },
  })
}

const EDITABLE_ORDER_FIELDS = [
  'parcelPrice',
  'description',
  'deliveryType',
  'address',
  'tin',
  'passportFullname',
  'serviceRepacking',
]

export function createOrder(
  token: ?string,
  { trackingNumber, ...restOrderValues }: OrderValuesType
): AxiosPromise<*> {
  const order = {
    trackingNumber: trackingNumber.trim(),

    ...pick(restOrderValues, EDITABLE_ORDER_FIELDS),
  }

  return axiosClient.post(
    'orders',
    { order },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function editOrder(
  token: ?string,
  orderId: number,
  formValues: Object
): AxiosPromise<*> {
  return axiosClient.put(
    `orders/${orderId}`,
    pick(formValues, EDITABLE_ORDER_FIELDS),
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function deleteOrder(token: ?string, orderId: number): AxiosPromise<*> {
  return axiosClient.delete(`orders/${orderId}`, {
    headers: {
      Authorization: token,
    },
  })
}

export function payOrder(token: ?string, orderId: number): AxiosPromise<*> {
  return axiosClient.post(
    `orders/${orderId}/payments`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function createPaymentIntent(
  token: ?string,
  amount: ?number,
  payment_gateway: 'stripe' | 'square' = 'stripe'
) {
  return axiosClient.post(
    'users/payment_intents',
    {
      exchange_code: 'KZTUSD',
      payment_gateway,
      amount,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function payOrderInsurance(
  token: ?string,
  orderId: number
): AxiosPromise<*> {
  return axiosClient.post(
    `orders/${orderId}/insurances`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

// Domain
export const PARCEL_STATUS = {
  NEW: 0, // Ожидаем
  PREPAYMENT_INVOICE: 1, // Получили
  PACKAGING: 2, // На упаковке
  EXECUTING: 3, // Отправили
  FINAL_INVOICE: 4, // Готов к выдаче / Оплачен
  ON_DELIVERY: 5, // На доставке
  WON: 6, // Выполнен (Доставлен)
  LOSE: 7, // Отменен
}

export const PARCEL_STATUS_SLUG = {
  new: PARCEL_STATUS.NEW,
  'prepayment-invoice': PARCEL_STATUS.PREPAYMENT_INVOICE,
  packaging: PARCEL_STATUS.PACKAGING,
  executing: PARCEL_STATUS.EXECUTING,
  'final-invoice': PARCEL_STATUS.FINAL_INVOICE,
  'on-delivery': PARCEL_STATUS.ON_DELIVERY,
  won: PARCEL_STATUS.WON,
  lose: PARCEL_STATUS.LOSE,
}
export const DELIVERY_SERVICE = {
  FEDEX: 'fedex',
  DHL: 'dhl',
  ONTRAC: 'ontrac',
  USPS: 'usps',
  UPS: 'ups',
}
export const DELIVERY_TYPE = {
  DELIVERY: 0,
  PICKUP: 1,
}

// Cities
export const REGION_IDS = {
  ALMATY: 1,
  BISHKEK: 2,
}

export const COUNTRIES_BY_REGION_ID = {
  '1': 'kz',
  '2': 'kg',
}

export const REGION_ID_BY_COUNTRY_CODE = {
  kz: REGION_IDS.ALMATY,
  kg: REGION_IDS.BISHKEK,
}

const API_ERROR_CODES = {
  INVALID_PASSWORD: 'invalid_password',
  EMAIL_NOT_FOUND: 'email_not_found',
}

// Utils
export function parseResponseErrors(errorResponse: Object) {
  let errors

  if (errorResponse.data && errorResponse.data.error) {
    if (errorResponse.data.error === API_ERROR_CODES.INVALID_PASSWORD) {
      errors = { password: strings.validation.invalidPassword }
    }

    if (errorResponse.data.error === API_ERROR_CODES.EMAIL_NOT_FOUND) {
      errors = { email: strings.validation.emailNotFound }
    }
  }

  if (errorResponse.data && errorResponse.data.errors) {
    errors = mapValues(errorResponse.data.errors, (value) =>
      Array.isArray(value) ? value.join(', ') : value
    )
  }

  return errors
}

export function createRecipient(
  token: string,
  recipient: Object
): AxiosPromise<Object> {
  return axiosClient.post(
    'recipients',
    { recipient },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function updateRecipient(
  token: string,
  id: string,
  recipient: Object
): AxiosPromise<Object> {
  return axiosClient.put(
    `recipients/${id}`,
    { recipient },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function deleteRecipient(
  token: string,
  id: string
): AxiosPromise<Object> {
  return axiosClient.delete(`recipients/${id}`, {
    headers: {
      Authorization: token,
    },
  })
}
